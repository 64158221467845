import logo from "./assets/logo-home.png"
import css from "./Logo.module.css"

export default function Logo() {
    return (
        <div className={css.field}>
            <a href="https://lawyersforprojects.com/">
                <img className={css.image} src={logo} alt="Visit the Lawyers for Projects site" />
            </a>
        </div>
    )
}