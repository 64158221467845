import { Alert, AlertTitle } from "@mui/material"
import css from "./Answered.module.css"
import logo from "./assets/logo-home.png"

export default function Answered() {
    return (
        <div className={css.container}>
            <Alert className={css.alert} severity="info">
                <AlertTitle><strong>Información</strong></AlertTitle>
                Este formulario ya ha sido contestado.<br />
                Gracias por su respuesta.
            </Alert>
            <a href="https://lawyersforprojects.com/">
                <img src={logo} alt="Visit the Lawyers for Projects site" />
            </a>
        </div>
    )
}