import { FormHelperText, InputAdornment, OutlinedInput } from "@mui/material"
import React from "react"
import css from "./Input.module.css"

export interface InputProps {
    label: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    formId: string
}

export default function Input({ label, onChange, formId }: InputProps) {

    const onBlur = () => {
        const form = document.getElementById(formId)! as HTMLFormElement
        form.requestSubmit()
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    return (
        <div className={css.input} onBlur={onBlur}>
            <form id={formId} onSubmit={onSubmit}>
                <OutlinedInput
                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                    type="number"
                    inputProps={{ "min": 0, 'step': 0.01 }}
                    required={true}
                    size="small"
                    onChange={onChange}
                    placeholder="0"
                />
                <FormHelperText id="outlined-weight-helper-text">{label}</FormHelperText>
            </form>
        </div>
    )
}