import css from "./Element.module.css"
import { Card, CardContent, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

export interface ElementProps {
    name: string
    contents: string | Array<string> | boolean | number | undefined
}

export default function Element({ name, contents }: ElementProps) {
    const LFPRender = (x: string, i: number) => {
        return (
            <div className={css.LFPManager}>
                <List>
                    <ListItem className={css.listItem} key={"person"}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                        }}>
                            <PersonIcon />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {x[0]}
                        </Typography>
                    </ListItem>
                    <ListItem className={css.listItem} key={"mail"}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                        }}>
                            <EmailIcon />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {x[1]}
                        </Typography>
                    </ListItem>
                </List>
            </div>
        )
    }
    return (
        <div className={css.container}>
            <Card>
                <CardContent>
                    <Typography className={css.title} variant="h6">
                        {name}
                    </Typography>
                    {typeof contents === "string" || typeof contents === "number" ? contents :
                        Array.isArray(contents) ?
                            <ul itemType="circle" className={css.list}>
                                {contents.map((x, i) => {
                                    if (name === 'Responsable Lawyers for Projects') {
                                        return LFPRender(x, i)
                                    }
                                    return (
                                        <li key={i}>
                                            <Typography variant="body1">
                                                {x}
                                            </Typography>
                                        </li>
                                    )
                                })}
                            </ul> :
                            typeof contents === "boolean" ?
                                <Typography variant="body1">
                                    {contents ? "Sí" : "No"}
                                </Typography> :
                                undefined
                    }
                </CardContent>
            </Card>
        </div >
    )
}