import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import css from "./Option.module.css"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useEffect, useRef } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Honorarios from "./Honorarios";

export interface OptionProps {
    onSendClick: (acceptance: boolean, text?: string, map?: Map<string, boolean>) => void
    required: boolean
    helperText: string
    type: "accept" | "reject"
    reasons?: string[]
    handleError?: (message: string) => void
    handleChange?: (lowNum: number, highNum: number) => void
    honorariumType?: string
}

export default function Option({ onSendClick, required, helperText, type, reasons, handleError, handleChange, honorariumType }: OptionProps) {

    const textRef = useRef<HTMLInputElement>(null)
    const reasonMap = new Map<string, boolean>(reasons?.map(x => [x, false]))
    useEffect(() => {
        window.scrollTo({
            top: document.getElementById("container")?.scrollHeight,
            behavior: 'smooth'
        })
    }, [])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        reasonMap.set(event.target.id, event.target.checked)
    }

    return (
        <div className={css.explanationContainer}>
            {type === "reject" &&
                <Accordion sx={{borderRadius: "4px"}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h4 className={css.header}>Motivos del rechazo</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {reasons?.map((x, i) => <FormControlLabel control={<Checkbox id={x} onChange={onChange} />} label={x} key={i} />)}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            }
            {type === "accept" &&
                <Honorarios handleError={handleError!} honorariumType={honorariumType} handleChange={handleChange!} />}
            <h4 className={css.header}>Comentarios</h4>
            <TextField
                inputRef={textRef}
                className={css.explanation}
                required={required}
                multiline
                id="outlined-basic"
                label={required ? "Requerido" : "Opcional"}
                defaultValue=""
                helperText={helperText}
            />
            <Button className={css.sendButton}
                variant="outlined"
                onClick={() => onSendClick(type === "accept", textRef.current?.value, reasonMap.size ? reasonMap : undefined)}>
                <SendRoundedIcon />
                Enviar
            </Button>
        </div>
    )
}