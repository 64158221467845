import { Outlet, useLocation } from "react-router-dom"
import Logo from "./Logo"

export default function Landing() {
    const location = useLocation()
    return (
        <>
            {location.pathname === "/" &&
                <Logo />
            }
            <Outlet />
        </>
    )
}