export default async function request(method: string, path: string, body?: Object) {
    const response = await fetch(path, {
        method,
        headers: {
            "Content-Type": "application/json",
            "SECURE":"ff3a5884a2604eb7ba81c3a36e1281bf6a2303dd804f19f7a288329d65524d3c"
        },
        body: JSON.stringify(body)
    })
    return response
}