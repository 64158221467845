import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Opportunity from './Opportunity';
import Landing from './Landing';
import Answered from './Answered';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={"/opportunity"}>
      <Routes>
        <Route path="*" element={<Navigate to="/" replace={true} />} />
        <Route path="answered" element={<Answered />} />
        <Route path="/" element={<Landing />}>
          <Route path=":oppID" element={<Opportunity />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
