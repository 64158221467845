import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import request from './utils';
import css from "./Opportunity.module.css"
import Element from './Element';
import { Alert, AlertTitle, AppBar, Skeleton, Toolbar } from '@mui/material';
import Validation from './Validation';
import logo from "./assets/logo-home.png"
import MetadataType from './Interface';

export interface Token {
    oppID?: string
    FCID: string | null
    token: string | null
}

export default function Opportunity() {
    const [opp, setOpp] = useState<any>()
    const [code, setCode] = useState<number>()
    const [validationCode, setValidationCode] = useState<number>()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState<string>()
    const [airMap, setAirMap] = useState<Map<string, MetadataType>>(new Map())
    const [airtableTitle, setAirtableTitle] = useState<MetadataType>()
    const [limitDate, setLimitDate] = useState<Date>()
    const [deleteMap, setDeleteMap] = useState<Map<string, any>>(new Map())
    const navigate = useNavigate();

    const token: Token = {
        oppID: params.oppID,
        FCID: searchParams.get("fcid"),
        token: searchParams.get("t"),
    }

    useEffect(() => {
        fetchToken(token)
        fetchOpp()
    }, [])

    const fetchToken = async (body: Token) => {
        try {
            const response = await request("POST", `${process.env.REACT_APP_URL}/mail`, body)
            if (response.status === 201) {
                setLimitDate(new Date(await response.json()))
                return
            } else if (response.status === 401) {
                if (!body.token || !body.FCID) {
                    setError("Faltan parámetros en la URL. Revísela.")
                    setValidationCode(500)
                } else {
                    navigate("/", { replace: true })
                }
            } else if (response.status === 403) {
                navigate("/answered", { replace: true })
            } else if (response.status === 404) {
                setError(`Las condiciones de la oportunidad han canviado.
                Consulte con Lawyers for Projects para más información.`)
                setValidationCode(404)
            } else if (response.status === 409) {
                setError("El link ha caducado. Pida que le envíen otro.")
                setValidationCode(409)
            } else {
                if (!body.token || !body.FCID) {
                    setError("Faltan parámetros en la URL. Revísela.")
                }
                setValidationCode(500)
            }
        } catch (error) {
            console.log(error)
            setValidationCode(500)
        }
    }

    const fetchOpp = async () => {
        let data: any
        let statusCode: number
        try {
            const response = await request("GET", `${process.env.REACT_APP_URL}/opportunity_form/${params.oppID}`)   
            statusCode = response.status
            data = statusCode === 200 ? (await response.json()) : false;
        } catch (error) {
            statusCode = 500
            console.log(error)
        }
        if (data) {
            const metadata: MetadataType[] = data.metadata
            const dbMap = new Map(metadata.map(x => [x.db_name, x]))
            const metadataAirtableMap = new Map(metadata.map(x => [x.airtable_name, x]))
            setAirMap(metadataAirtableMap)
            const metaNames = metadata.map(x => x.airtable_name)
            let names = Object.keys(data.opp).filter(x => metaNames.includes(x))
            if (!names.includes(dbMap.get("in_person")!.airtable_name)) {
                names = names.filter(x => {
                    return !["location", "province", "foreign_city"].includes(metadata.find(y => {
                        return y.airtable_name === x
                    })!.db_name)
                })
            }
            setAirtableTitle(dbMap.get("name"))
            const deleteValues = ['essential_speciality_criterion_1', 'essential_speciality_criterion_2', 'honorarium_type', 'province', 'foreign_city']
            const dataClone = { ...data.opp }
            for (const value of deleteValues) {
                const metaValue = dbMap.get(value)
                if (metaValue) {
                    setDeleteMap(x => {
                        const copyMap = new Map(x)
                        copyMap.set(value, data.opp[metaValue.airtable_name])
                        return copyMap
                    })
                    delete dataClone[metaValue.airtable_name]
                }
            }
            const fields = Object.fromEntries(Object.entries(dataClone).filter(([key]) => names.includes(key)))
            setOpp(fields)
        }
        setCode(statusCode)
    }

    const elementArray = () => {
        const prop = ([key]: [string, unknown]) => airMap.get(key)?.order
        return Object.entries(opp).sort((a, b) => {
            const [aO, bO] = [a, b].map(prop)
            return aO && bO ? aO - bO : 0
        }).map(([key, value]) => {
            if (key !== airtableTitle?.airtable_name) {
                switch (airMap.get(key)?.db_name) {
                    case 'LFP_manager':
                        key = "Responsable Lawyers for Projects"
                        break
                    case 'description':
                        key = 'Breve descripción del proyecto'
                        break
                    case 'jurisdiction':
                        key = 'Jurisdicción aplicable'
                        break
                    case 'essential_speciality_1': {
                        const msg = "Se requieren de manera imprescindible las siguientes Especialidades"
                        const criterion = deleteMap.get('essential_speciality_criterion_1')
                        if (criterion === "Todas") {
                            key = msg + ` (${criterion.toLowerCase()}):`
                        } else if (criterion === "Cualquiera") {
                            key = "Se requieren de manera imprescindible cualquiera de las siguientes Especialidades:"
                        } else {
                            key = msg + ":"
                        }
                        break
                    }
                    case 'essential_speciality_2': {
                        const msg = "Alternativamente, se requiere de manera imprescindible las siguientes Especialidades"
                        const criterion = deleteMap.get('essential_speciality_criterion_2')
                        if (criterion === "Todas") {
                            key = msg + ` (${criterion.toLowerCase()}):`
                        } else if (criterion === "Cualquiera") {
                            key = "Alternativamente, se requiere de manera imprescindible cualquiera las siguientes Especialidades:"
                        } else {
                            key = msg + ":"
                        }
                        break
                    }
                    case 'desired_speciality':
                        key = 'Se valorarán las siguientes Especialidades:'
                        break
                    case 'essential_languages':
                        key = 'Idiomas imprescindibles para el proyecto:'
                        break
                    case 'desired_languages':
                        key = 'Idiomas valorables para el proyecto:'
                        break
                    case 'in_house':
                        key = value ? 'Se requiere experiencia in-house' : 'No se requiere experiencia in-house'
                        value = undefined
                        break
                    case 'monthly_hours':
                        key = 'Horas de dedicación al mes'
                        break
                    case 'in_person':
                        key = 'Se requiere presencialidad en las oficinas del cliente'
                        value = undefined
                        break
                    case 'location':
                        key = 'Ubicación de las oficinas del cliente'
                        const locProvince = deleteMap.get('province')
                        const locForeignCity = deleteMap.get('foreign_city')
                        const loc = [Array.isArray(locProvince) ? locProvince : undefined, Array.isArray(locForeignCity) ? locForeignCity : undefined].flat()
                        value = `${value} (${loc.filter(x => x).join(", ")})`
                        break
                    case 'province':
                        key = 'Ubicación de las oficinas del cliente'
                        break
                    case 'foreign_city':
                        key = 'Ubicación de las oficinas del cliente'
                        break
                    case 'date_1':
                        key = 'Fecha de inicio del proyecto'
                        value = (new Date(value as string)).toLocaleDateString("es-ES", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })
                        break
                    case 'date_2':
                        key = 'Fecha de finalización del proyecto'
                        value = (new Date(value as string)).toLocaleDateString("es-ES", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })
                        break
                }
                return <Element name={key} contents={value as string | Array<string> | boolean | number | undefined} key={key} />
            }
        })
    }

    return (
        <div className={css.container} id="container">
            {!validationCode ? <AppBar className={css.appBar} position='sticky' sx={{
                backgroundColor: "#b0b0b0",
            }}>
                <Toolbar className={css.toolbar} disableGutters={false}>
                    <img className={css.logo} src={logo} alt="Lawyers for Projects logo" />
                    {limitDate && <span className={css.limitDate}>
                        Fecha límite:<br /> {limitDate?.toLocaleDateString("es-ES", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })}
                    </span>}
                </Toolbar>
            </AppBar> : undefined}
            {!validationCode ?
                <>
                    <header>
                        {code === 200 ?
                            <div>
                                <h1>{airtableTitle && opp[airtableTitle.airtable_name]}</h1>
                            </div> :
                            code === 500 ?
                                <Alert severity="error">
                                    <AlertTitle><strong>Error</strong></AlertTitle>
                                    No se ha encontrado la oportunidad — Revise la URL
                                </Alert> :
                                <Skeleton variant="text" width={200} height={80} />
                        }
                    </header>
                    {code !== 500 ?
                        <div className={css.body}>
                            {code === 200 ?
                                <div className={css.lowerContainer}>
                                    <h2>Datos de la Oportunidad</h2>
                                    <div className={css.arrayContainer}>
                                        {elementArray()}
                                    </div>
                                    <Validation honorariumType={deleteMap.get('honorarium_type')} />
                                </div> :
                                <div className={css.skeleton}>
                                    <Skeleton variant="text" width="5em" />
                                    <Skeleton variant="rectangular" height="7em" />
                                    <Skeleton variant="text" width="5em" />
                                    <Skeleton variant="rectangular" height="7em" />
                                    <Skeleton variant="text" width="5em" />
                                    <Skeleton variant="rectangular" height="7em" />
                                    <Skeleton variant="text" width="5em" />
                                    <Skeleton variant="rectangular" height="7em" />
                                </div>
                            }
                        </div> :
                        undefined}
                </> :
                <div className={css.error}>
                    {error &&
                        <Alert className={css.alert} severity="error">
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            {error}
                        </Alert>
                    }
                    <a href="https://lawyersforprojects.com/">
                        <img className={css.image} src={logo} alt="Visit the Lawyers for Projects site" />
                    </a>
                </div>
            }
        </div>
    )
}