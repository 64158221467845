import Input from "./Input";
import css from "./MoneyInput.module.css"
import { useState } from "react";

export interface MoneyInputProps {
    handleError: (message: string) => void
    handleChange: (low: number, high: number) => void
}

export default function MoneyInput({ handleError, handleChange }: MoneyInputProps) {
    const [lowValue, setLowValue] = useState<number>(0)
    const [highValue, setHighValue] = useState<number>(0)

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const id = e.relatedTarget?.parentNode?.parentElement?.id
        if (id && ["lower", "higher"].includes(id)) return
        if (highValue < lowValue) {
            handleError("El rango superior no puede ser menor al inferior.")
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, high: boolean) => {
        e.preventDefault()
        const num = parseFloat(parseFloat(e.target.value).toFixed(2))
        if (high) {
            setHighValue(num)
            handleChange(lowValue, num)
        } else {
            setLowValue(num)
            handleChange(num, highValue)
        }
        
    }

    return (
        <div onBlur={onBlur}>
            <div className={css.InputWrapper}>
                <Input label="Rango inferior" onChange={(e) => onChange(e, false)} formId="lower" />
                <Input label="Rango superior" onChange={(e) => onChange(e, true)} formId="higher" />
            </div>
        </div>
    )
}