import { Alert, AlertColor, Backdrop, Button, CircularProgress, Snackbar, SnackbarCloseReason } from "@mui/material"
import { useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import Option from "./Option";
import request from "./utils";
import css from "./Validation.module.css"

export interface ValidationProps {
    honorariumType?: string
}

export default function Validation({ honorariumType }: ValidationProps) {
    const [discard, setDiscard] = useState(false)
    const [accept, setAccept] = useState(false)
    const [acceptColor, setAcceptColor] = useState('#4caf50')
    const [discardColor, setDiscardColor] = useState('#ef5350')
    const [searchParams, setSearchParams] = useSearchParams();
    const [snackOpen, setSnackOpen] = useState(false)
    const [severity, setSeverity] = useState<AlertColor | undefined>(undefined)
    const [message, setMessage] = useState<string>()
    const [disabled, setDisabled] = useState(false)
    const [backDrop, setBackDrop] = useState(false)
    const [low, setLow] = useState<number>()
    const [high, setHigh] = useState<number>()
    const [honorType, setHonorType] = useState<string>()
    const params = useParams()
    const onClick = (e: "accept" | "discard") => {
        if (e === "accept") {
            setDiscard(false)
            setAccept(true)
            setDiscardColor('#ef5350')
            setAcceptColor('#2e7d32')
        }
        if (e === "discard") {
            setDiscard(true)
            setAccept(false)
            setAcceptColor('#4caf50')
            setDiscardColor('#d32f2f')
        }
    }

    const handleSnackClose = (reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") return
        setSnackOpen(false)
    }

    const onSendClick = async (acceptance: boolean, text?: string, map?: Map<string, boolean>) => {
        if (accept) {
            if (!low || !high) return
            if (high < low) return
        }
        setSnackOpen(false)
        setAccept(false)
        setDiscard(false)
        setBackDrop(true)
        const body = {
            acceptance,
            reason: text,
            FCID: searchParams.get("fcid"),
            motives: map && discard ? Array.from(map.entries()).filter(item => item[1]).map(item => item[0]) : undefined,
            honorarium: low && high && accept ? [low, high] : undefined,
            honorType,
        }
        console.log(body)
        const response = await request("PATCH", `${process.env.REACT_APP_URL}/opportunity_form/${params.oppID}`, body)
        setBackDrop(false)
        if (response.status === 201) {
            setSeverity('success')
            setSnackOpen(true)
            setMessage("Respuesta enviada correctamente.")
            setDisabled(true)
        } else if (response.status === 403) {
            setSeverity("warning")
            setSnackOpen(true)
            setMessage("Solo se puede enviar una respuesta.")
        } else {
            setSeverity('error')
            setSnackOpen(true)
            setMessage("Ha ocurrido un problema, inténtelo de nuevo.")
        }

    }

    const handleError = (message: string) => {
        setSnackOpen(false)
        setSeverity('warning')
        setMessage(message)
        setSnackOpen(true)
    }

    const handleChange = (lowNum: number, highNum: number) => {
        setLow(lowNum)
        setHigh(highNum)
        setHonorType(honorariumType)
    }

    return (
        <div className={css.validationContainer}>
            <h2 className={css.validationTitle}>Tu decisión</h2>
            <div className={css.buttonWrapper}>
                <Button className={css.accept} disabled={disabled} sx={{ bgcolor: acceptColor }} variant="contained" onClick={() => onClick("accept")}>Acepto</Button>
                <Button className={css.discard} disabled={disabled} sx={{ bgcolor: discardColor }} variant="contained" onClick={() => onClick("discard")}>Descarto</Button>
            </div>
            {accept && <Option
                onSendClick={onSendClick}
                required={false}
                helperText="Dinos si tienes alguna pregunta o comentarios."
                type="accept"
                handleChange={handleChange}
                handleError={handleError}
                honorariumType={honorariumType}
            />}
            {discard && <Option
                onSendClick={onSendClick}
                required={false}
                helperText="Dinos si tienes alguna pregunta o comentarios."
                type="reject"
                reasons={["No me interesa", "No tengo disponibilidad", "Está fuera de mi especialidad", "Tengo un conflicto de interés", "Otros"]}
            />}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={snackOpen} autoHideDuration={4000} onClose={(e, reason) => handleSnackClose(reason)}>
                <Alert onClose={() => handleSnackClose()} severity={severity} variant='filled' sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}