
import css from "./Honorarios.module.css"
import MoneyInput from "./MoneyInput";

export interface HonorariosProps {
    honorariumType?: string
    handleError: (message: string) => void
    handleChange: (low: number, high: number) => void
}

export default function Honorarios({ honorariumType, handleError, handleChange }: HonorariosProps) {

    return (
        <div className={css.HonorariosContainer}>
            <h4 className={css.header}>Honorarios</h4>
            <h5 className={css.header}>{honorariumType}</h5>
            <MoneyInput handleError={handleError} handleChange={(a, b) => handleChange(a, b)} />
        </div>
    )
}